export const SCREENS = {
  SUBSCRIPTION: { id: "subscription", path: "/subscription" },
  SETTINGS: { id: "settings", path: "/settings" },
  DAILY_EDGE_SHOW: { id: "daily-edge-show", path: "/daily-edge" },
};

export const ROUTES = Object.freeze({
  HOME: "",
  SETTINGS: "/settings",
  DAILY_EDGE: "/daily-edge",
  TRADE_IDEAS: "/trade-ideas",
  MONTHLY_OUTLOOK: "/monthly-outlook",
  LEARN: "/learn",
});
