export default class MyClientUtils {
  /**
   *
   * @param {string} dateStr
   * @returns {string}
   */
  static convertDateFormat(dateStr) {
    // Parse the date
    const parts = dateStr.split(".");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // months are 0-indexed in JavaScript
    const day = parseInt(parts[2], 10);

    const dateObj = new Date(year, month, day);

    // Format the date
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return dateObj.toLocaleDateString("en-US", options);
  }

  /**
   *
   * @param {Date} d
   * @returns {number}
   */
  static getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    return weekNo;
  }

  static async retryUntilTrue(func, maxAttempts, intervalTime) {
    console.log("retryUntilTrue called");
    let attempts = 0;

    return new Promise((resolve, reject) => {
      const attemptFunc = async () => {
        attempts++;
        const result = await func();

        if (result) {
          resolve(true);
        } else if (attempts < maxAttempts) {
          setTimeout(attemptFunc, intervalTime);
        } else {
          reject(new Error("Max attempts reached"));
        }
      };

      attemptFunc();
    });
  }
}
