import { DailyEdgeData } from "./AppData.js";
import MyClientUtils from "./MyClientUtils.js";
import ScreenManager, { Screen } from "./ScreenManager.js";
import { ROUTES, SCREENS } from "./appConstants.js";
import { DailyEdgeShow } from "./AppData.js";

export default class DailyEdgeTab {
  /**
   *
   * @param {HTMLElement} showListDomElement
   * @param {ShowScreen} showScreen
   */
  constructor(showListDomElement, showScreen) {
    this.domElement = showListDomElement;
    this.showScreen = showScreen;

    this.listContainer = this.domElement; // could be inner child!

    /**
     * @type {DailyEdgeShow}
     */
    this.selectedShow = null;
  }

  /**
   *
   * @param {string} dateStr
   */
  openShowScreenForDate(dateStr) {
    // Find the show for the given date
    const show = model.getShowByDateStr(dateStr);
    this.openShowScreen(show);
  }

  /**
   *
   * @param {DailyEdgeShow} show
   */
  openShowScreen(show) {
    let path = ROUTES.DAILY_EDGE + "/" + show.dateStr;
    ScreenManager.getInstance().getScreen(SCREENS.DAILY_EDGE_SHOW.id).path = path;
    ScreenManager.getInstance().openScreenById(SCREENS.DAILY_EDGE_SHOW.id);
    this.selectedShow = show;
    this.showScreen.show = show;
  }

  set data(data) {
    this._data = data;
    this.render(data);
  }

  get data() {
    return this._data;
  }

  /**
   * @param {DailyEdgeData} dailyEdgeData
   */
  render(dailyEdgeData) {
    this.listContainer.innerHTML = "";
    let i = 0;
    let shows = dailyEdgeData.dailyEdgeShows;

    this.showCards = [];

    shows.forEach((show, index) => {
      const elementName = index == 0 ? "today-show-card" : "show-card";
      const showCard = document.createElement(elementName);

      showCard.id = "showCard" + index;
      showCard.show = show;

      const thumbnail = showCard.querySelector(".thumb-img");
      if (thumbnail.complete) {
        thumbnail.style.opacity = 1;
      } else {
        thumbnail.onload = () => {
          thumbnail.classList.add("loaded");
        };
      }
      showCard.addEventListener("click", () => {
        this.openShowScreen(show);
      });

      this.showCards.push(showCard);
    });

    this.showCards.forEach((showCard) => {
      this.listContainer.appendChild(showCard);
    });
  }
}

export class ShowScreen extends Screen {
  /**
   *
   * @param {HTMLElement} domElement
   */
  constructor(domElement) {
    super(domElement, SCREENS.DAILY_EDGE_SHOW.id, SCREENS.DAILY_EDGE_SHOW.path);

    this.closeBtn = this.domElement.querySelector("#close-show-button");
    this.videoPlayer = this.domElement.querySelector("#show-video-player");
    this.iframe = this.domElement.querySelector("#ai-iframe");
    this.title = this.domElement.querySelector("#show-screen-header .title");
    this.dateLabel = this.domElement.querySelector("#show-screen-header .date");
    this.noChatMsg = this.domElement.querySelector("#no-chat-msg");

    this.domElement.onBeforeClose = () => {
      this.videoPlayer.pause();
    };
  }

  /**
   * @param {DailyEdgeShow} show
   */
  set show(show) {
    this.noChatMsg.style.visibility = "hidden";
    this._show = show;
    this.title.textContent = show.title;
    this.dateLabel.textContent = show.prettyDate;
    this.videoPlayer.src = show.videoURL;

    let iframeURL = show.iframeURL == null || show.iframeURL == "" ? null : show.iframeURL;
    this.iframe.src = show.iframeURL == null ? "about:blank" : show.iframeURL;
    this.iframe.style.display = iframeURL == null ? "none" : "block";
    this.noChatMsg.style.visibility = iframeURL == null ? "visible" : "hidden";
  }

  get show() {
    return this._show;
  }
}

// Register the ShowCard web component
export class ShowCard extends HTMLElement {
  connectedCallback() {
    // according to spec, connectedCallback is called when the element is added to the DOM
    this.classList.add("show-card"); // now it's safe to access this.innerHTML and this.ownerDocument
  }
  /**
   * @param {DailyEdgeShow} show
   */
  set show(show) {
    this._show = show; // Store the entire data object
    let dateStr = show.dateStr;
    show.prettyDate = MyClientUtils.convertDateFormat(dateStr);

    if (show.altWeek == 1) {
      this.classList.add("alt-week");
    }

    this.innerHTML = this.render();
  }
  get show() {
    return this._show;
  }

  render() {
    return /*html*/ `
    <div class="show-details">
      <div class="show-date">${this._show.prettyDate}</div>
      <div class="show-title">${this._show.title}</div>
    </div>
    <div class="show-thumb" data-video-url="${this._show.videoURL}">
      <img class="thumb-img" src="${this._show.thumbURL}" alt="${this._show.title} Thumbnail" />
      <div class="show-length">${this._show.length}</div>
    </div>`;
  }
}

customElements.define("show-card", ShowCard);

export class TodayShowCard extends ShowCard {
  connectedCallback() {
    // according to spec, connectedCallback is called when the element is added to the DOM
    super.connectedCallback();
    this.classList.add("today-show"); // now it's safe to access this.innerHTML and this.ownerDocument
  }

  render() {
    const pipClass = this.show.pipSide === "right" ? "pip-right" : "pip-left";
    return /*html*/ `
    <div class="show-details">
      <div class="show-date">${this.show.prettyDate}</div>
      <div class="show-title">${this.show.title}</div>
    </div>
    <div class="show-thumb" data-video-url="${this.show.videoURL}">
      <img class="thumb-img" src="${this.show.mikeThumbURL}" alt="${this.show.title} Presentor Thumbnail" />
      <img class="thumb-img pip ${pipClass}" src="${this.show.thumbURL}" alt="${this.show.title} Chart Thumbnail" />
      <div class="show-length">${this.show.length}</div>
    </div>`;
  }
}

customElements.define("today-show-card", TodayShowCard);
